.swiper {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin: auto;
	align-items: center;
	justify-content: center;

	margin-top: -4px;

	@include lg {
		margin-top: 32px;
		margin: auto;
	}
}

.swiper__container {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.swiper__object {
	align-items: center;
	justify-content: center;
	height: auto;

	* > img {
		margin: auto;
	}

	@include xs {
		width: 350px;
	}

	@include lg {
		width: 450px;
	}
}

.swiper__slide {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
}
