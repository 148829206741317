@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./variables/breakpoints.scss";
@import "./variables/colors.scss";
@import "./variables/text.scss";

@import "./pages/home.scss";
@import "./pages/dashboard.scss";

@import "./navigation/mobile-menu.scss";
@import "./navigation/navbar.scss";
@import "./navigation/top-menu.scss";
@import "./navigation/vertical-menu.scss";

@import "./typography/typography.scss";

@import "./components/authentication.scss";
@import "./components/blob.scss";
@import "./components/button.scss";
@import "./components/cart.scss";
@import "./components/checkout.scss";
@import "./components/datepicker.scss";
@import "./components/dropdown.scss";
@import "./components/error.scss";
@import "./components/form.scss";
@import "./components/grid.scss";
@import "./components/input.scss";
@import "./components/logo.scss";
@import "./components/media-overview.scss";
@import "./components/mobile-warning.scss";
@import "./components/modal.scss";
@import "./components/page.scss";
@import "./components/payment.scss";
@import "./components/product.scss";
@import "./components/selector-button.scss";
@import "./components/slider.scss";
@import "./components/svg-background.scss";
@import "./components/table.scss";

@import "./components/dashboard/buttons.scss";
@import "./components/dashboard/layout.scss";

@import "./vendors/stripe.scss";

@import "./layout.scss";

html,
body {
	margin: 0;
	padding: 0;
	font-family: "Open Sans", sans-serif;
	font-size: 16px;
	background-color: #000000;
	color: #fff;
	--swiper-theme-color: #fff;
}

main,
.section {
	display: flex;
	flex-direction: column;
	padding: 16px 16px;

	@include lg {
		padding: 16px 96px;
	}
}
