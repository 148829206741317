.overlay {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal {
	display: flex;
	flex-direction: column;
	min-width: 728px;
	min-height: 728px;
	// padding: 0px 8px;
	background-color: $color-900;
	border-radius: 8px;
	z-index: 100;
	border: 1px solid $color-600;

	&__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 80px;
		padding: 0 16px;
	}
}

.modal-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0px 16px;

	&__image {
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		object-fit: contain;
		position: relative;

		img {
			width: 100%;
			height: 728px;
			object-fit: contain;
		}
	}

	&__button {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.modal-gallery {
		flex-direction: row;
	}
}
