.logo {
	display: flex;
	justify-content: center;
	align-items: center;

	&__text {
		@include logo-text;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 4px;
	}

	svg {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}

.logo-small {
	height: 48px;
}

.logo-medium {
	height: 72px;
}

.logo-large {
	height: 96px;
}
