.calendar {
	flex-direction: column;
	width: 100%;
	align-self: flex-start;

	&__label {
		@include subtitle;
		justify-content: flex-start;
		width: 100%;
		// text-align: left;
	}

	// input {
	@include body-text;
	display: flex;
	color: $color-100;
	width: 288px;
	justify-content: center;
	align-items: center;
	text-align: left;

	&:autofill,
	&:focus,
	&:active {
		border-color: $color-primary;
		// background-color: $color-800;
		// outline: none;
	}

	// autofilled styles
	&:-webkit-autofill,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:active {
		-webkit-text-fill-color: $color-100;
		-webkit-box-shadow: 0 0 0px 1000px $color-800 inset;
		transition: background-color 5000s ease-in-out 0s;
		border-color: $color-primary;
	}

	&:hover {
		border-color: $color-secondary;
	}
}

.datepicker {
	display: flex;
	flex-direction: row;
	padding: 0px 8px;
	border-radius: 8px;
	width: 100%;
	background-color: $color-800;
	color: $color-100;
	justify-content: space-between;
	align-items: center;
	position: relative;
	border: 1px solid $color-primary;

	input {
		display: inline-flex;
		color: $color-100;
		background-color: $color-800;
		// background-color: red;
		border-radius: 8px;
		// width: 64px !important;
		height: 48px;
		font-size: 16px;

		// disable arrows
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:focus {
			color: $color-100;
		}
		outline: none;
	}

	&__calendar {
		display: flex;
		position: absolute;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 288px;
		padding: 8px;
		border-radius: 8px;
		border: 1px solid $color-primary;
		background-color: $color-900;
		z-index: 1000;

		button {
			margin: 0px 4px;
		}
	}

	.hidden {
		display: none;
	}
}
