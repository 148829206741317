.svg-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	max-width: 100%;
	min-height: 100vh;
	height: 100%;
	pointer-events: none;
	z-index: 0;
	margin: 0;

	svg {
		width: 100%;
		min-height: 100vh;
		height: 100%;
		max-width: 100%;
		margin: 0;
	}
}
