.product {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100%;
	height: 100%;
	min-height: 100%;
	max-width: 100%;
	z-index: 1;
	background-color: $color-800;
	border-radius: 8px;
	padding: 16px;
	overflow-y: auto;
}

.back-link {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 48px;
	margin-bottom: 16px;
	cursor: pointer;

	&__text {
		font-size: 16px;
		font-weight: 600;
		color: $color-100;
	}
}

.product-layout {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.product-gallery {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 50%;
	height: 512px;

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: $color-100;
		width: 512px;
		padding: 32px;
		border-radius: 16px;
		height: 100%;
	}

	&__image {
		display: flex;
		width: 100%;
		height: 100%;
		border-radius: 16px;

		img {
			border-radius: 16px;
			width: 100%;
			height: 100%;
		}
	}
}

.product-details {
	display: flex;
	flex-direction: column;
	width: 50%;
	height: 100%;

	&__variants {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		border-radius: 16px;

		margin: 16px 0;

		> * {
			margin-right: 16px;
		}
	}

	&__price {
		font-size: 24px;
		font-weight: 700;
		margin-top: 16px;
	}

	&__variant {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: fit-content;
		height: fit-content;
		padding: 16px 32px;
		background-color: $color-100;
		border-radius: 16px;
		color: $color-900;
		cursor: pointer;
		border: 2px solid $color-100;

		&:hover {
			background-color: $color-400;
			border: 2px solid $color-primary;
			color: $color-100;
		}

		&__value {
			font-size: 16px;
			font-weight: 600;
			margin-right: 16px;
		}
	}

	&__variant--selected {
		background-color: $color-primary;
		border: 2px solid $color-primary;
		color: $color-100;
	}
}
