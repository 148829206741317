.grid {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: $color-900;
	border-radius: 8px;
	margin: 8px 8px;
	justify-content: space-between;
}

.grid-item {
	background-color: $color-800;
	border-radius: 8px;
	margin: 8px;
	padding: 32px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 350px;
	width: 100%;
	cursor: pointer;

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		width: 100%;
	}

	&__description {
		font-size: 14px;
		font-weight: 600;
		color: $color-400;
	}

	&__thumbnail {
		display: flex;
		width: 172px;
		height: 172px;
		border-radius: 16px;

		img {
			border-radius: 16px;
			width: 100%;
			height: 100%;
		}
	}

	&__price {
		font-size: 16px;
		font-weight: 700;
		margin-top: 16px;
		color: $color-100;
	}
}
