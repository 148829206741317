.dashboard-button {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 220px;
	width: 220px;
	padding: 32px 16px;
	background-color: $color-primary;
	text-align: center;

	border: 2px solid $color-primary;
	border-radius: 16px;

	&:hover {
		cursor: pointer;
	}

	&__secondary {
		background-color: $color-secondary;
		border: 2px solid $color-secondary;
	}

	&__tertiary {
		background-color: $color-tertiary;
		border: 2px solid $color-tertiary;
	}

	&__disabled {
		background-color: $color-600;
		border: 2px solid $color-600;
		color: $color-400;
		&:hover {
			cursor: not-allowed;
		}
	}

	&__double-width {
		width: 240;
	}

	&__double-height {
		height: 240;
	}

	&__inverted {
		background-color: transparent;
	}
}
