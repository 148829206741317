.dashboard-layout {
	display: flex;
	height: 100vh;
	width: 100vw;
	min-height: 100vh;
	max-width: 100vw;

	&__content {
		height: 100vh;
		width: 100%;
		min-height: 100vh;
		max-width: 100vw;
		padding: 16px 32px;
		background-color: $color-900;
	}
}

@include md {
	.dashboard-layout {
		&__content {
			width: calc(100vw - 80px);
		}
	}
}
