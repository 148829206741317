.mobile-menu {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: $color-900;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 80px;
		width: 100%;
		padding: 0 16px;
	}

	&__side {
		display: flex;
		width: 100px;
		align-items: center;
		height: 50px;
	}

	.right {
		display: flex;
		justify-content: flex-end;
	}

	&__logo {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 50px;
		height: 50px;

		svg {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}

	.expanded {
		display: flex;
		width: 100vw;
		height: 100vh;
		background-color: $color-900;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		position: "absolute";
		top: 0;
		left: 0;
		padding: 16px;

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 80px;
			width: 100%;
		}

		&__side {
			display: flex;
			width: 50px;
			justify-content: flex-start;
			// align-items: center;
			height: 100%;
		}

		.right {
			display: flex;
			justify-content: flex-end;
		}
	}

	&__menu {
	}
}
