.media-overview {
	background-color: $color-900;
	// height: 100%;
	width: 624px;
}

.media-uploader {
	border: 1px solid $color-primary;
	border-radius: 8px;
	height: 500px;
	width: 100%;
	padding: 16px;
	overflow-y: auto;
}

.media-items {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	padding: 16px 0px;
}

.media-item {
	border-radius: 8px;
	margin: 8px 0px;
	border: 1px solid $color-primary;
	height: 200px;
	width: 200px;
	img {
		width: 100%;
		height: 100%;
		border-radius: 8px;
		object-fit: contain;
	}
}
