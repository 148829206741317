.card {
	background-color: $color-900;
	display: flex;
	flex-direction: column;
	max-height: 400px;
	width: 100%;
	height: 400px;
	border-radius: 8px;
	position: relative;
	border: 1px solid $color-700;
	cursor: pointer;

	@include md {
		width: 400px;
		height: 400px;
	}

	&__image {
		width: 100%;
		height: 200px;
		position: relative;

		img {
			width: 100%;
			height: 200px;
			border-radius: 8px 8px 0 0;
			object-fit: contain;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 200px;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.8) 100%
			);
			border-radius: 8px 8px 0 0;
		}
	}

	&__content {
		padding: 16px;
		position: relative;
		display: flex;
		flex-grow: 1;
		flex-direction: column;

		justify-content: space-between;
	}
}
