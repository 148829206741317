.form {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	border-radius: 8px;

	&__dark {
		background-color: $color-900;
	}

	& > * {
		margin: 8px 0px;
	}
}

.form__double {
	display: flex;
	flex-direction: row;
	align-items: center;

	& > * {
		margin: 8px 0px;
	}
}
