.error {
	background-color: $color-failure;
	text-align: center;
	padding: 4px 8px;
	border-radius: 4px;
	margin: 8px 0px;
}

.error-list {
	display: flex;
	flex-direction: column;
}

.error-item {
	display: flex;
}
