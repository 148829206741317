.input {
	margin: 16px 0px;
	width: 100%;
	&__label {
		@include subtitle;
	}

	&__half {
		display: flex;
		flex-direction: row;

		p {
			text-transform: capitalize;
		}

		input {
			width: 136px !important;
		}
	}

	&__file {
		@include body-text;
		display: flex;
		background-color: $color-primary;
		color: $color-100;
		width: 288px;
		height: 50px;
		border-radius: 8px;
		font-size: 16px;
		padding: 0px 8px;
		justify-content: center;
		align-items: center;
		border: 1px solid;
		border-color: $color-primary;
		cursor: pointer;

		&:autofill,
		&:focus,
		&:active {
			border-color: $color-primary;
			// background-color: $color-800;
			// outline: none;
		}

		// autofilled styles
		&:-webkit-autofill,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:active {
			-webkit-text-fill-color: $color-100;
			-webkit-box-shadow: 0 0 0px 1000px $color-800 inset;
			transition: background-color 5000s ease-in-out 0s;
			border-color: $color-primary;
		}

		&:hover {
			border-color: $color-secondary;
		}
	}

	input {
		@include body-text;
		display: flex;
		background-color: $color-800;
		color: $color-100;
		width: 288px;
		height: 50px;
		border-radius: 8px;
		font-size: 16px;
		padding: 0px 8px;
		justify-content: center;
		align-items: center;
		border: 1px solid;
		border-color: $color-primary;

		&:autofill,
		&:focus,
		&:active {
			border-color: $color-primary;
			// background-color: $color-800;
			// outline: none;
		}

		// autofilled styles
		&:-webkit-autofill,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:active {
			-webkit-text-fill-color: $color-100;
			-webkit-box-shadow: 0 0 0px 1000px $color-800 inset;
			transition: background-color 5000s ease-in-out 0s;
			border-color: $color-primary;
		}

		&:hover {
			border-color: $color-secondary;
		}
	}
	.checkbox {
		display: flex;
		background-color: $color-800;
		margin: 4px 0px;
		width: 36px;
		height: 36px;
		border-radius: 8px;
		border: 1px solid $color-primary;

		justify-content: center;
		align-items: center;
	}

	.hidden {
		display: none;
	}

	&__error {
		@include body-caption;

		color: red;
		margin: 0px 0px;
	}

	&__column {
		display: flex;
		flex-direction: column;
	}

	&__secondary input,
	input:focus {
		border-color: $color-secondary;
	}

	&__secondary input:hover {
		border-color: $color-primary;
	}

	&__tertiary input {
		border-color: $color-tertiary;
	}

	&__centered {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	&__caption {
		@include body-caption;
		color: $color-300;
	}
}
