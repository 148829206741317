$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Extra large devices
$screen-xxl-min: 1400px;

@mixin xs {
	@media (min-width: 0) {
		@content;
	}
}

@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}

// Medium devices
@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}

// Large devices
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}

// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}

// Extra extra large devices
@mixin xxl {
	@media (min-width: #{$screen-xxl-min}) {
		@content;
	}
}
