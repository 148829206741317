.home {
	height: 100vh;
	width: 100vw;
	min-height: 100vh;
	max-width: 100vw;
	padding: 0 16px;
}

// Center logo and login fields
// Background is SVG
