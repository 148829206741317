.grabber {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	height: 600px;
	min-height: 600px;
	margin: 16px 0px;
	position: relative;

	z-index: 1;

	@include lg {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		height: 600px;
		min-height: 600px;
		margin: 120px 0px;
		position: relative;
	}

	&__items {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 100%;
		width: 100%;
		height: 600px;
		position: relative;
		overflow-x: hidden;

		@include lg {
			flex-direction: row;
			width: 100%;
		}

		@include xl {
			width: 70%;
		}
	}

	&__content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		height: 100%;
		position: absolute;
		z-index: 100;

		text-align: center;

		@include lg {
			width: 70%;
			flex-direction: row;
			text-align: left;
		}

		&__part {
			margin-top: 32px;
			display: flex;
			width: 80%;
			flex: 1;

			@include lg {
				width: 100%;
				margin-top: 0px;
			}

			&--right {
				justify-content: center;

				@include lg {
					justify-content: flex-end;
				}
			}
		}

		&__text {
			h3 {
				@include body-title;
			}

			p {
				@include body-text;
			}
		}

		&__title {
			@include large-title;
		}

		&__cta {
			@include subtitle;
			display: flex;

			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 150px;
			height: 60px;
			padding: 16px;
			border-radius: 32px;
			color: $color-900;
			background-color: $color-100;

			@include lg {
				margin-top: 32px;
			}
		}
	}
}

.grab-circle {
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 200%;
	height: 100%;
	border-radius: 100%;
	border: 12px solid $color-900;

	overflow-x: hidden;

	left: 50%;
	transform: translateX(-25%);

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&:nth-child(1) {
		left: 0;
	}

	@include lg {
		position: absolute;
		width: 600px;
		height: 600px;
		left: unset;
		transform: unset;
		overflow-x: unset;
	}

	&:nth-child(2) {
		left: 50%;
		transform: translateX(-50%);
	}

	&:nth-child(3) {
		right: 0;
	}
}
