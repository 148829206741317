.page {
	display: flex;
	position: relative;
	// width: 100vw;
	flex-direction: column;
	height: 100%;
	min-height: 100vh;
	margin-top: 55px;
	width: 100%;
	max-width: 100vw;
	// overflow-x: hidden;
	z-index: 1;
}

.content {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100%;
	height: 100%;
	min-height: 100%;
	max-width: 100%;
	z-index: 1;
	background-color: $color-800;
	border-radius: 8px;
	padding: 16px;
	overflow-y: auto;
}

.container {
	display: flex;
	position: relative;
	flex-direction: column;
	// align-items: center;
	width: 100%;
	height: 100%;
	min-height: 100%;
	max-width: 100%;
	z-index: 1;
	border-radius: 8px;

	@include lg {
		padding: 16px 100px;
	}
}

.content-header {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
