.dashboard-layout {
	display: flex;
	height: 100%;
	width: 100%;
	max-height: 100%;
	max-width: 100%;
}

.dashboard-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
	height: 100%;
	width: 100%;
	max-height: 100%;
	max-width: 100%;

	// all children margin bottom
	& > * {
		margin-right: 16px;
		margin-bottom: 16px;
	}
}

.dashboard-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
	width: 100%;
	max-width: 100%;

	// all children margin right
	& > * {
		margin-right: 16px;
	}
}
