.payment {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
	max-width: 100%;
	height: 100%;
	background-color: $color-900;
	// background-color: pink;
	border-radius: 8px;
	justify-content: space-between;
	padding: 16px;
}

.payment-layout {
	display: flex;
	flex-direction: row;
	padding: 64px;
	justify-content: space-between;
	align-items: flex-start;
}

.payment-form {
	border-radius: 8px;
	width: 400px;
	padding: 32px;
	min-height: 400px;
	background-color: $color-100;
}

.payment-options {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			padding: 4px 4px 4px 0px;
			margin: 4px 4px 4px 0px;
			cursor: pointer;
		}
	}
}
