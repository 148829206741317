@import "ui/style/main.scss";

@import "components/archive-block.scss";
@import "components/banners.scss";
@import "components/card.scss";
@import "components/checkin-carousel.scss";
@import "components/content-block.scss";
@import "components/cta-block.scss";
@import "components/flexible-page.scss";
@import "components/footer.scss";
@import "components/grabber.scss";
@import "components/hero.scss";
@import "components/landing.scss";
@import "components/layout.scss";
@import "components/locations.scss";
@import "components/media-block.scss";
@import "components/settings.scss";
@import "components/swiper.scss";
@import "components/unique-selling-points.scss";

@import "variables/breakpoints.scss";

html,
body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	font-size: 16px;
	background-color: #000000;
	color: #fff;
	max-width: 100vw;
	min-height: 100%;
}
