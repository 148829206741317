.cart {
	display: flex;
	// flex-grow: 1;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	height: 100%;
	background-color: $color-900;
	border-radius: 8px;
	justify-content: space-between;
}

.cart-item {
	// background-color: red;
	width: calc(100% - 32px);
	border-radius: 8px;
	margin: 16px;
	padding: 0px 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	max-width: 100%;
	height: 100px;
	border-bottom: 1px solid $color-800;

	&__items {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	&__thumbnail {
		display: flex;
		width: 64px;
		height: 64px;
		border-radius: 8px;
		margin-right: 32px;

		img {
			border-radius: 8px;
			width: 100%;
			height: 100%;
		}
	}

	&__title {
		@include body-title;
	}

	&__price {
		@include subtitle;
	}
}
