.slider {
	display: flex;
	flex-direction: column;
	margin: 16px 0px;
	width: 100%;

	&__label {
		@include subtitle;
		width: 100%;
		justify-content: flex-start;
		// text-align: left;
	}

	&__error {
		@include body-caption;

		color: red;
		margin: 0px 0px;
	}

	&__secondary input,
	input:focus {
		border-color: $color-secondary;
	}

	&__secondary input:hover {
		border-color: $color-primary;
	}

	&__tertiary input {
		border-color: $color-tertiary;
	}

	input[type="range"] {
		appearance: none;
		width: 100%;
		height: 8px;
		border-radius: 8px;
		background: $color-primary;
		outline: none;
		-webkit-transition: 0.2s;
		transition: opacity 0.2s;

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			transform: translateY(-25%);
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background: $color-primary-lightest;
			cursor: pointer;
		}
	}
}
