.checkout {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	height: 100%;
	background-color: $color-900;
	border-radius: 8px;
	justify-content: space-between;
}
