.settings-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 32px;
	padding: 16px;
	border-top: 1px solid $color-border;
	border-bottom: 1px solid $color-border;
}
