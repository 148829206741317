.table-container {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: $color-900;
	border-radius: 8px;
	margin: 8px 0px;
}

.table {
	display: table;
	width: 100%;
	border: 1px solid $color-600;
	border-radius: 8px;

	&__header {
		display: table-header-group;
		width: 100%;
		height: 40px;
	}

	&__header_cell {
		display: table-cell;
		height: 40px;
		border: 1px solid $color-600;
		text-align: left;
		padding: 8px;
	}

	&__header_row {
		display: table-row;
		width: 100%;
	}

	&__body {
		display: table-row-group;
		width: 100%;
		height: 100%;
		padding: 0;
	}

	&__row {
		display: table-row;
		width: 100%;
		padding: 0;
	}

	&__cell {
		@include text-main;
		display: table-cell;
		height: 40px;
		background-color: $color-800;
		border: 1px solid $color-600;
		padding: 8px;
	}

	&__footer {
		display: table-footer-group;
		text-align: left;
		width: 100%;
		height: 40px;

		* > * {
			padding-left: 8px;
		}
	}
}

.table-pagination {
	display: flex;
	margin-left: auto;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin: 16px 0px;
	width: 100%;
	height: 40px;

	select {
		width: 80px;
		height: 40px;
		border-radius: 8px;
		border: 1px solid $color-600;
		background-color: $color-900;
		color: $color-100;
		text-align: center;
		margin: 0px 8px;
	}

	input {
		width: 40px;
		height: 40px;
		border-radius: 8px;
		border: 1px solid $color-600;
		background-color: $color-900;
		color: $color-100;
		text-align: center;
		margin: 0px 8px;
	}
}

.table-filter {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 16px 0px;
	width: 100%;

	&__builder {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 16px 0px;
		width: 100%;
		height: 40px;

		button {
			width: 120px;
			height: 40px;
			border-radius: 8px;
			border: 1px solid $color-600;
			background-color: $color-900;
			color: $color-100;
			text-align: center;
			margin: 0px 8px;
		}

		select {
			width: 200px;
			height: 40px;
			border-radius: 8px;
			border: 1px solid $color-600;
			background-color: $color-900;
			color: $color-100;
			text-align: center;
			margin: 0px 8px;
		}

		input {
			width: 200px;
			height: 40px;
			border-radius: 8px;
			border: 1px solid $color-600;
			background-color: $color-900;
			color: $color-100;
			text-align: center;
			margin: 0px 8px;
		}
	}
}

@include md {
	.table-filter {
		&__builder {
			flex-direction: row;
		}
	}
}
