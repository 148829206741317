.banners {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	@include lg {
		justify-content: flex-start;
		align-items: center;
	}

	.banner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		margin: 0px 8px;

		@include sm {
			width: 150px;

			svg {
				width: 150px;
			}
		}

		@include lg {
			justify-content: center;
			align-items: center;
		}

		.title {
			@include title;
		}

		.subtitle {
			@include subtitle;
		}
	}
}
