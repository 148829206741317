.authentication-component {
	display: flex;
	flex-direction: column;
	background-color: $color-background;
	border-color: $color-primary;
	align-items: center;
	border-width: 1px;
	border-radius: 16px;
	padding: 16px 4px;
	width: 100%;

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// height: 100%;
		// padding: 32px;
		width: 100%;

		.container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
		}
	}

	@include lg {
		justify-content: space-between;
		padding: 32px;
		align-items: center;
		margin: auto;
		min-height: 500px;
		width: 500px;

		&___content {
			padding: 32px;
		}
	}
}
