.hero {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	// height: 100vh;
	// background-color: #000000;
	// color: #fff;
	// background-size: cover;
	// background-position: center;
	// background-repeat: no-repeat;
	// text-align: center;
	// font-size: 2rem;
	// font-weight: 700;
	// text-transform: uppercase;
	// letter-spacing: 0.2rem;
	// text-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
	// padding: 0 1rem;
	// z-index: 1;
}
