.space-between {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.align-center {
	display: flex;
	align-items: center;
}

.justify-center {
	display: flex;
	justify-content: center;
}

.column {
	display: flex;
	flex-direction: column;
}

.row {
	display: flex;
	flex-direction: row;
}
