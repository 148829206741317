@mixin text-main {
	color: $color-text-main;
	font-size: 16px;
	font-weight: 300;
}

@mixin text-menu {
	color: $color-text-menu;
	font-size: 16px;
	font-weight: 400;
}

@mixin text-input-placeholder {
	color: $color-text-menu;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0;
}

@mixin body-caption {
	font-size: 12px;
}

@mixin body-text {
	font-size: 20px;

	@include lg {
		font-size: 16px;
	}
}

@mixin body-title {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: bold;
}

@mixin button-option-text {
	font-size: 10px;
	text-transform: uppercase;
	font-weight: 400;
}

@mixin button-subtitle {
	font-size: 28px;
	text-transform: uppercase;
	font-weight: bold;
}

@mixin button-text {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: bold;
}

@mixin button-data {
	font-size: 16px;
	text-transform: uppercase;
	font-weight: bold;
}

@mixin button-title {
	font-size: 24px;
	text-transform: uppercase;
	font-weight: bold;
	&__small {
		font-size: 20px;
	}
}

@mixin caption-date {
	font-size: 14px;
	font-weight: 600;
	&__small {
		font-size: 10px;
	}
}

@mixin chat-text {
	font-size: 13px;
}

@mixin logo-text {
	font-size: 24px;
	// text-transform: uppercase;
	font-weight: bold;
}

@mixin subtitle {
	font-size: 16px;
	text-transform: uppercase;
	font-weight: bold;
}

@mixin title {
	font-size: 48px;
	text-transform: uppercase;
	font-weight: bold;
	margin: 0;
}

@mixin large-title {
	font-size: 48px;
	font-weight: bold;
	margin: 0;

	@include lg {
		font-size: 72px;
	}
}

@mixin medium-title {
	font-size: 48px;
	font-weight: bold;
	text-transform: uppercase;
	margin: 0;

	@include lg {
		font-size: 36px;
	}
}

@mixin card-title {
	font-size: 24px;
	text-transform: uppercase;
	font-weight: bold;
	margin: 0;
}

@mixin usp-description {
	font-size: 24px;
	margin: 0;
}

@mixin title-caption {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
}

@mixin title-subcaption {
	font-size: 10px;
	text-transform: uppercase;
	font-weight: bold;
}
