.selector-button-group {
	display: flex;
	flex-direction: row;
	// justify-content: space-between;
	align-items: center;

	& > *:not(:last-child) {
		margin-right: 8px;
	}
}

.selector-button--primary {
	display: inline-flex;
	background-color: transparent;
	border-color: $color-primary;
	min-width: 88px;
	width: 88px;
	height: 32px;
	border-radius: 32px;
	color: $color-900;
	font-size: 16px;
	// margin: 16px 0px;
	// padding: 8px;
	text-align: center;
	justify-content: center;
	align-items: center;
	border: 1px solid $color-primary;

	&:hover {
		background-color: $color-primary;
		border-color: $color-primary;
		cursor: pointer;
	}
}

.selector-button--active {
	background-color: $color-primary;
	border-color: $color-primary;
	color: $color-900;
}

.selector-button--small {
	width: 144;
	height: 60;
}
.selector-button--medium {
	width: 216;
	height: 60;
}
.selector-button--large {
	width: 288;
	height: 60;
}
.selector-button--narrow {
	width: 80;
	height: 48;
	border-radius: 8;
}
.selector-button--compact {
	width: 144;
	height: 48;
	border-radius: 8;
}
.selector-button--wide {
	width: 288;
	height: 48;
	border-radius: 8;
}
.selector-button--secondary {
	background-color: $color-secondary;
	border-color: $color-secondary;
}
.selector-button--tertiary {
	background-color: $color-tertiary;
	border-color: $color-tertiary;
}
.selector-button--inverted {
	background-color: transparent !important;
	color: $color-100;
}
.selector-button--disabled {
	background-color: $color-700 !important;
	color: $color-100;
}

.button-container {
	margin-top: 16px;
}
