.vertical-menu {
	display: flex;
	height: 100%;
	width: 100px;
	flex-direction: column;
	background-color: $color-900;
	// background-color: salmon;
	z-index: 10;
	border-right: 1px solid $color-600;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0px;

	&__filler {
		width: 80px;
		position: relative;
	}

	&__logo {
		display: flex;
		width: 48px;
		justify-content: center;
	}

	&__items {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		margin-top: 64px;
		height: 100%;
		width: 100%;
	}

	&__bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		width: 100%;
	}

	&__header {
		display: flex;
		align-items: center;
		height: 80px;
		width: 100%;
		padding: 0 16px;
	}
}

.collapsed {
	width: 80px;
	position: relative;
}

.expanded {
	width: 200px;
	position: absolute;
}

.vertical-menu-item {
	margin: 16px auto;
	width: 48px;
	position: relative;

	&__icon {
		height: 36px;
		width: 36px;
		// width: 100%;
		margin: 0 auto;
		height: 100%;
		object-fit: contain;
	}

	&__title {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		text-align: right;
	}

	&__bubble {
		@include title-subcaption;
		background-color: $color-primary;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		position: absolute;
		bottom: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.expanded_item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0px 28px;
	border-radius: 8px;
}

.expanded_logo {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 152px;
}
