.dropdown {
	display: flex;
	flex-direction: column;
	margin: 16px 0px;
	width: 100%;

	&__label {
		@include subtitle;
		width: 100%;
		justify-content: flex-start;
		// text-align: left;
	}

	// input {
	@include body-text;
	color: $color-100;
	// justify-content: flex-start;
	// align-items: flex-start;
	text-align: left;

	&:autofill,
	&:focus,
	&:active {
		border-color: $color-primary;
		// background-color: $color-800;
		// outline: none;
	}

	// autofilled styles
	&:-webkit-autofill,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:active {
		-webkit-text-fill-color: $color-100;
		-webkit-box-shadow: 0 0 0px 1000px $color-800 inset;
		transition: background-color 5000s ease-in-out 0s;
		border-color: $color-primary;
	}

	&:hover {
		border-color: $color-secondary;
	}
	// }

	&__arrow {
		margin-left: 8px;
		color: $color-100;
	}

	&__base {
		// background-color: $color-800;
		width: 288px;
		height: 100%;
		padding: 16px;
		// transform: translateY(50px);
	}

	&__control {
		width: 288px;
		height: 100%;
		background-color: $color-800;
		border: 1px solid $color-primary;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0px 8px;
		height: 50px;
		cursor: pointer;
	}

	&__menu {
		position: absolute;
		background-color: $color-800;
		z-index: 10000;
		width: 288px;
		& > div {
			padding: 8px;
			border: 1px solid $color-primary;
			border-width: 0px 1px 1px 1px;
			cursor: pointer;
		}
	}

	&__placeholder {
		width: 288px;
		color: $color-100;
		text-align: center;
		align-items: center;
		justify-content: center;
		height: 50px;
		padding: 16px;
		border-radius: 8px;
	}

	&__error {
		@include body-caption;

		color: red;
		margin: 0px 0px;
	}

	&__secondary input,
	input:focus {
		border-color: $color-secondary;
	}

	&__secondary input:hover {
		border-color: $color-primary;
	}

	&__tertiary input {
		border-color: $color-tertiary;
	}
}
