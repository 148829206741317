.flexible-page {
	display: flex;
	margin-top: 16px;
	height: 100%;
	width: 100%;
	flex-direction: column;
	align-items: center;

	justify-content: flex-start;

	&__content {
		width: 100%;
	}

	@include lg {
		margin-top: 100px;

		&__content {
			width: 80%;
		}
	}
}
