.navbar {
	// position: relative;
	position: fixed;
	width: 100%;
	z-index: 100;
	top: 0;

	input {
		padding: 8px 24px 8px 48px;
		border-radius: 40px;
		border: 1px solid $color-900;
		width: 100%;

		font-size: 16px;
		line-height: 24px;

		color: $color-700;
		background-color: $color-900;

		transition: background-color 0.2s ease;

		::placeholder {
			color: $color-400;
			opacity: 0.6;
		}

		&:hover {
			cursor: pointer;
		}

		&:active,
		&:focus-visible {
			outline: none;
			background-color: $color-900;
		}
	}
}

.navbar .navbar__default {
	margin-bottom: -55px;
	background: linear-gradient(
		180deg,
		#19181a 0%,
		rgba(25, 24, 26, 0.0001) 100%
	);
	z-index: 2;

	// padding: 8px 100px;

	.container::before {
		position: absolute;
		content: "";
		top: 0;
		right: 0;
		// bottom: -55px;
		bottom: 0;
		left: 0;
		background: rgba($color-900, 0.7);
		z-index: -1;
		transition: opacity 0.4s ease;
	}

	&.navbar__default--scrolled-to-top .container::before {
		opacity: 0;
	}

	.navbar__side {
		display: flex;
		flex: 1;
	}

	.navbar__center {
		display: flex;
		// flex: 1;
		// background-color: $color-700;
		border-radius: 40px;
	}

	.container {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
	}

	.navbar__items {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 8px 24px;
	}

	.navbar__item {
		display: flex;
		width: auto;
		position: relative;
		padding: 8px 24px;
		background-color: color-900;
		border-radius: 40px;
		color: $color-100;

		&:hover {
			cursor: pointer;
			background-color: $color-100;
			color: $color-900;
		}

		// &:hover::after {
		// 	width: 100%;
		// 	opacity: 1;
		// }

		&--basic {
			border: 1px solid $color-400;

			&:hover {
				background-color: $color-100;
				color: $color-900;
			}
		}

		&--cta {
			background-color: $color-secondary;
			color: $color-100;
			transition: background-color 0.2s ease;
			margin-left: 24px;

			&:hover {
				background-color: $color-secondary-darker;
			}

			&::after {
				display: none;
			}
		}
	}

	@include xs {
		display: none;
	}

	@include lg {
		display: flex;
	}
}

.navbar-open {
	display: flex;
	height: 100vh;
	width: 100vw;
	position: sticky;
	background-color: $color-900;
	padding: 32px;
	z-index: 10;

	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;

		> * {
			padding: 16px 0px;
		}
	}

	.close {
		position: absolute;
		top: 24px;
		right: 24px;
		width: 24px;
		height: 24px;
		cursor: pointer;
	}
}

.navbar-reponsive {
	height: 80px;
	z-index: 100;
	position: relative;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.logo {
		width: 48px;

		&__image {
			width: 36px;
		}
		&__name {
			h3 {
				font-size: 20px;
			}
		}
	}

	&__icon {
		width: 48px;
		padding: 16px 0px;
	}

	@include xs {
		display: flex;
	}

	@include lg {
		display: none;
	}
}
