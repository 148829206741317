.stripe-form {
	color: $color-100;
}

.stripe-input-creditcard {
	@include body-text;
	// display: flex;
	// background-color: $color-900;
	border: $color-primary 1px solid;
	border-radius: 8px;
	width: 432px;
	height: 72px;
	padding: 8px;
	justify-content: center;
	align-items: center;

	// &:autofill,
	// &:focus,
	// &:active {
	// 	border-color: $color-primary;
	// 	// background-color: $color-800;
	// 	// outline: none;
	// }

	// autofilled styles
	&:-webkit-autofill,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:active {
		-webkit-text-fill-color: $color-100;
		-webkit-box-shadow: 0 0 0px 1000px $color-800 inset;
		transition: background-color 5000s ease-in-out 0s;
		border-color: $color-primary;
	}

	// &:hover {
	// 	border-color: $color-secondary;
	// }
}

.stripe-input-address {
	@include body-text;
	// display: flex;
	// background-color: $color-900;
	border: $color-primary 1px solid;
	border-radius: 8px;
	width: 432px;
	padding: 8px;
	justify-content: center;
	align-items: center;
	// &:autofill,
	// &:focus,
	// &:active {
	// 	border-color: $color-primary;
	// 	// background-color: $color-800;
	// 	// outline: none;
	// }

	// autofilled styles
	&:-webkit-autofill,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:active {
		-webkit-text-fill-color: $color-100;
		-webkit-box-shadow: 0 0 0px 1000px $color-800 inset;
		transition: background-color 5000s ease-in-out 0s;
		border-color: $color-primary;
	}

	// &:hover {
	// 	border-color: $color-secondary;
	// }
}
