.body-caption {
	font-size: 12px;
	font-family: "Open Sans";
}

.caption {
	font-size: 14px;
	font-family: "Open Sans";
	color: $color-300;
}

.body-text {
	font-size: 16px;
	font-family: "Open Sans";
}

.subtitle {
	@include subtitle;
}

.button-text {
	@include button-text;
}

.button-subtitle {
	@include button-subtitle;
}

.button-data {
	@include button-data;
}

.button-title {
	@include button-title;
}

.title {
	@include title;
}

.large-title {
	@include large-title;
}

.medium-title {
	@include medium-title;
}

h1 {
	@include title;
}

h2 {
	@include body-title;
}

h3 {
	@include subtitle;
}

.centered {
	text-align: center;
}

.error-text {
	color: red;
}

.success-text {
	color: $color-success;
}

.link {
	color: $color-primary;
	text-decoration: none;
	cursor: pointer;
}
