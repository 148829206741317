.top-menu {
	// width: 200px;
	height: 50px;
	margin-top: 16px;
	justify-content: flex-end;
	// background-color: red;

	&__item {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		height: 50px;
		// margin: 8px 0px;
		// padding: 0px 16px;
		border-radius: 8px;
		background-color: $color-800;
		color: $color-100;
		font-size: 16px;
		font-weight: 500;
		cursor: pointer;

		&:hover {
			background-color: $color-900;
		}

		&__icon {
			margin-right: 8px;
		}
	}
}
