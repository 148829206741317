.locations-carousel {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
	gap: 16px;
}

.locations-card {
	display: flex;
	position: relative;
	flex-direction: column;
	margin: 16px 0;
	height: 400px;
	width: 250px;
	border-radius: 16px;
	background-size: cover;
	background-position: center;
	text-align: center;

	&:hover {
		cursor: pointer;
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 100%;
		width: 100%;
		height: 100%;
		align-items: center;
		position: absolute;
		padding: 16px;
		background-color: rgba(0, 0, 0, 0.2);
	}

	&__title {
		font-size: 24px;
		color: white;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
	}

	&__stats {
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: space-between;
		width: 100%;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
		padding: 0 16px;
	}
}
