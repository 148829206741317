$color-primary: #3544dc;
$color-primary-lighter: #5764e2;
$color-primary-lightest: #7f89ea;
$color-primary-darker: #1527d5;
$color-primary-darkest: #0d1b9e;
$color-secondary: #6f2ddb;
$color-secondary-lighter: #a379e9;
$color-secondary-lightest: #8750e1;
$color-secondary-darker: #590ed4;
$color-secondary-darkest: #41099c;
$color-tertiary: #c11ed8;
$color-tertiary-lighter: #d970e8;
$color-tertiary-lightest: #cc44df;
$color-tertiary-darker: #b803d1;
$color-tertiary-darkest: #850297;

$color-white: #ffffff;
$color-black: #000000;
$color-success: #5cb85c;
$color-warning: #f0ad4e;
$color-failure: #dc3544;
$color-red: #f5515f;
$color-blue: #007aff;
$color-100: #f8f8f8;
$color-200: #e6e6e6;
$color-300: #ccc;
$color-400: #a8a7a5;
$color-500: #555;
$color-600: #3d3d3d;
$color-700: #28282b;
$color-800: #1f1f1f;
$color-900: #000000;

$color-text-main: #fff;
$color-text-menu: #fff;
$color-background: #000;

$color-text-placeholder: #a1a1a1;

$color-input-background: $color-800;

$color-border: $color-400;
