.cta-block {
	display: flex;
	width: 100%;
	justify-content: center;

	padding: 32px 16px;
	@include md {
		padding: 32px 112px;
	}
}
