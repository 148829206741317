.landing {
	display: flex;
	flex-direction: column;
	height: 100vh;
	text-align: center;
	margin-top: -128px;
	align-items: center;
	justify-content: space-between;
	position: relative;

	&__background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-image: url("/images/landing/arm.jpg");
		background-size: cover;
		background-position: center;

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100px;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 1) 100%
			);
		}
	}

	&__scroll {
		position: absolute;
		bottom: 48px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		background: $color-primary;
		color: $color-white;
		padding: 16px;
		border-radius: 100%;
		z-index: 100;
	}

	&__content {
		width: 80%;
		margin: auto;
	}

	&__title {
		font-size: 2rem;
		font-weight: 700;
		text-transform: uppercase;
		padding: 0 1rem;
		margin: auto;
		z-index: 1;
	}

	&__subtitle {
		margin-top: 1rem;

		h3 {
			font-size: 1rem;
			font-weight: 500;
			padding: 0 1rem;
		}
	}

	.banners {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 1rem;
		margin-top: 1rem;
		margin-bottom: 96px;
		width: 100%;
		max-width: 100%;
		padding: 0 1rem;
	}
}

@include md {
	.landing {
		margin-top: -80px;

		.banners {
			margin-bottom: 96px;
		}
	}
}
