.button--primary {
	display: flex;
	background-color: $color-primary;
	border-color: $color-primary;
	width: 288px;
	height: 50px;
	border-radius: 8px;
	color: $color-900;
	margin: 16px 0px;
	text-align: center;
	justify-content: center;
	align-items: center;
	border: 1px solid $color-primary;

	&:hover {
		background-color: $color-primary;
		border-color: $color-primary;
		cursor: pointer;
	}
}
$blur: 4px;
$max: 870px;
$min: 0px;

.button--blur {
	overflow: hidden;
	background-color: transparent;
	border: 2px solid white;
	color: white;
	font-size: 18px;
	letter-spacing: 0.8px;
	text-transform: uppercase;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;
	-webkit-transform: translate3d(0, 0, 0);
	&:focus {
		outline: none;
	}
	&:before {
		transition: all 0.3s ease;
		-webkit-filter: blur($blur);
		filter: blur($blur);
	}

	&:hover:before {
		-webkit-filter: blur(0);
		filter: blur(0);
	}

	&:before {
		content: "";
		padding: 100%;
		top: -4px;
		left: -4px;
		right: -4px;
		// background: url($url) no-repeat;
		background-size: cover;
		background-attachment: fixed;
		background-position: 50%;
		-webkit-backface-visibility: hidden;
		-webkit-transform-style: preserve-3d;
		-webkit-transform: translate3d(0, 0, 0) scale(0);
	}
}

.button--small {
	width: 144px;
	height: 60px;
}
.button--medium {
	width: 216px;
	height: 60px;
}
.button--large {
	width: 288px;
	height: 60px;
}
.button--narrow {
	width: 80px;
	height: 48px;
	border-radius: 8;
}
.button--compact {
	width: 144px;
	height: 48px;
	border-radius: 8;
}
.button--wide {
	width: 288px;
	height: 48px;
	border-radius: 8;
}
.button--secondary {
	background-color: $color-secondary;
	border-color: $color-secondary;
}
.button--tertiary {
	background-color: $color-tertiary;
	border-color: $color-tertiary;
}
.button--inverted {
	background-color: transparent !important;
	color: $color-100;
}
.button--disabled {
	background-color: $color-700 !important;
	color: $color-100;
}

.button-container {
	margin-top: 16px;
}
