.footer {
	display: flex;
	position: relative;
	height: 400px;
	width: 100%;
	margin-top: 48px;
	padding: 32px 0px;
	justify-content: center;
	background-color: $color-900;
	border-top: 4px solid $color-800;

	@include lg {
		margin-top: 100px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		margin-top: 16px;
		align-items: center;
		text-align: center;
		width: 100%;
		height: 100%;

		@include lg {
			margin-top: 64px;
		}

		&__text {
			h3 {
				@include body-title;
			}
		}
	}

	.footer__links {
		display: flex;
		justify-content: center;
		text-align: center;

		align-items: center;
		margin-top: 10px;
		a {
			color: $color-400;
			margin: 0 10px;

			&:hover {
				color: $color-primary;
			}
		}
		p {
			color: $color-400;
			margin: 0 10px;

			&:hover {
				color: $color-primary;
			}
		}
	}
}
